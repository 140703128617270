<template>
    <el-header class="navbar">
        <breadcrumb class="breadcrumb-container"/>

        <div class="right-menu">
            <el-dropdown class="avatar-container" trigger="click">
                <div class="avatar-wrapper">
                    <img src="../assets/head.jpg" class="user-avatar">
                </div>
                <el-dropdown-menu slot="dropdown" class="user-dropdown">
                    <a target="_blank" href="https://github.com/Yonnxu" style="text-decoration: none">
                        <el-dropdown-item>
                            <span class="iconfont icon-github"></span>
                            <span>GitHub</span>
                        </el-dropdown-item>
                    </a>
                    <el-dropdown-item @click.native="logout">
                        <span class="iconfont icon-tuichu"></span>
                        <span>退出</span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </el-header>
</template>

<script>
    import Breadcrumb from "./Breadcrumb";

    export default {
        components:{Breadcrumb},
        methods: {
            logout() {
                window.sessionStorage.clear()
                this.$router.push('/login')
            },
        }
    }
</script>

<style lang="less" scoped>

    .nav-left-img {
        line-height: 46px;
        height: 100%;
        float: left;
        cursor: pointer;
        transition: background .3s;

        img{
            display: inline-block;
            vertical-align: middle;
            width: 20px;
            height: 20px;
        }
    }

    .right-menu {
        float: right;
        height: 100%;
        line-height: 50px;
    }

    .navbar {
        height: 50px !important;
        overflow: hidden;
        position: relative;
        background: #fff;
        box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
        user-select: none;

        .hamburger-container {
            line-height: 46px;
            height: 100%;
            float: left;
            cursor: pointer;
            transition: background .3s;
            -webkit-tap-highlight-color: transparent;

            &:hover {
                background: rgba(0, 0, 0, .025)
            }
        }

        .breadcrumb-container {
            float: left;
        }

        .right-menu {
            float: right;
            height: 100%;
            line-height: 50px;

            &:focus {
                outline: none;
            }

            .right-menu-item {
                display: inline-block;
                padding: 0 8px;
                height: 100%;
                font-size: 18px;
                color: #5a5e66;
                vertical-align: text-bottom;

                &.hover-effect {
                    cursor: pointer;
                    transition: background .3s;

                    &:hover {
                        background: rgba(0, 0, 0, .025)
                    }
                }
            }

            .avatar-container {

                .avatar-wrapper {
                    margin-top: 5px;
                    position: relative;

                    .user-avatar {
                        cursor: pointer;
                        width: 40px;
                        height: 40px;
                        border-radius: 10px;
                    }

                    .el-icon-caret-bottom {
                        cursor: pointer;
                        position: absolute;
                        right: -20px;
                        top: 0px;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .iconfont {
        width: 1em;
        height: 1em;
        overflow: hidden;
        font-size: 14px;
        margin-right: 5px;
    }

    .user-dropdown .svg {
        margin-right: 5px;
    }

    .el-dropdown-menu {
        margin: 7px 0 0 0 !important;
        padding: 0 !important;
        border: 0 !important;
    }

</style>