<template>
    <section class="main-content">
            <router-view></router-view>
    </section>
</template>

<script>
    export default {
        name: "MainContainer",
        computed: {
            key() {
                return this.$route.path
            }
        }
    }
</script>

<style scoped>
    .main-content {
        box-sizing: border-box;
        min-height: calc(100vh - 50px);
        width: 100%;
        position: relative;
        overflow: hidden;
        padding: 20px;
    }
</style>