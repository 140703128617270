<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  export default {
    name: 'App'
  }
</script>

<style>
  *{
    padding: 0;
    margin: 0;
    box-sizing: inherit;
    font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Arial,sans-serif;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
</style>
