<template>
  <div class="main-wrapper" >
    <sidebar ref="side"></sidebar>
    <div class="main-container">
      <navbar/>
      <main-container/>
    </div>
  </div>
</template>

<script>
  import Sidebar from "./Sidebar";
  import Navbar from "./Navbar";
  import MainContainer from "./main-content";

export default {
  name: 'Home',
  components: {Sidebar,Navbar,MainContainer},
}
</script>

<style>
  .main-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }
  .main-container{
    min-height: 100%;
    transition: margin-left 0.28s;
    margin-left: 190px;
    position: relative;
  }
</style>