<template>
    <el-breadcrumb class="app-breadcrumb" separator="/">
        <transition-group name="breadcrumb">
            <el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path">
                <span v-if="item.redirect==='noRedirect'||index==levelList.length-1" class="no-redirect">{{ item.meta.title }}</span>
                <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
            </el-breadcrumb-item>
        </transition-group>
    </el-breadcrumb>
</template>

<script>
    export default {
        name: "Breadcrumb",
        data() {
            return {
                levelList: null
            }
        },
        watch: {
            $route() {
                this.getBreadcrumb()
            }
        },
        created() {
            this.getBreadcrumb()
        },
        methods: {
            getBreadcrumb() {
                let matched = this.$route.matched.filter(item => item.meta && item.meta.title)
                const first = matched[0]
                // 判断当前界面是否是ManageHome
                if (!this.isManage(first)) {
                    matched = [{path: '/manageHome', meta: {title: 'Manage'}}].concat(matched)
                }
                this.levelList = matched
            },
            isManage(route) {
                const name = route && route.name
                if (!name) {
                    return false
                }
                // 去掉头尾空格装换为小写再比较
                return name.trim().toLocaleLowerCase() === 'ManageHome'.toLocaleLowerCase()
            },
            handleLink(item) {
                const {redirect, path} = item
                if(redirect===this.$route.path){
                    return
                }

                if (redirect) {
                    this.$router.push(redirect)
                    return
                }


                this.$router.push(path)
            }
        }
    }
</script>

<style lang="less" scoped>
    .app-breadcrumb.el-breadcrumb {
        display: inline-block;
        font-size: 14px;
        line-height: 50px;
        margin-left: 8px;
        .no-redirect {
            color: #97a8be;
            cursor: text;
        }
    }
</style>