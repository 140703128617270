<template>
    <el-aside :width="isCollapse ? '61px' : '190px'" class="sidebar-container">
        <el-scrollbar wrap-class="scrollbar-wrapper">
            <el-menu
                    class="el-menu"
                    :unique-opened="false"
                    :collapse-transition="false"
                    background-color="#304156"
                    text-color="#bfcbd9"
                    active-text-color="#409EFF"
                    :router="true"
                    :default-active="activeMenu">
                <div class="sidebar-logo">
                    <a href="/" style="">
                        <img src="../assets/head.jpg" alt="">
                        <h1 class="sidebar-title">Ieko</h1>
                    </a>
                </div>
                <div>
                    <el-menu-item class="menu-top el-menu-item" route="/manageHome" index="/manageHome">
                        <template slot="title">
                            <span class="iconfont icon-Home"></span>
                            <span>Manage</span>
                        </template>
                    </el-menu-item>
                </div>
                <el-submenu v-for="item in routes" :key="item.path" :index="item.path+''">
                    <template slot="title">
                        <span :class="item.meta.icon"></span>
                        <span>{{ item.meta.title }}</span>
                    </template>
                    <el-menu-item :index="item.path +'/'+ childItem.path"
                                  v-for="childItem in childroutes(item.children)"
                                  :key="childItem.id"
                    >
                        <template slot="title">
                            <span :class="childItem.meta.icon"></span>
                            <span>{{ childItem.meta.title }}</span>
                            <!-- 文本 -->
                        </template>
                    </el-menu-item>
                </el-submenu>
            </el-menu>
        </el-scrollbar>

    </el-aside>
</template>

<script>
    export default {
        name: "Sidebar",
        data() {
            return {
                menuList: [],
                isCollapse: false, // 是否折叠属性
                activePath: '',
            }
        },
        computed: {
            activeMenu() {
                const route = this.$route
                const {meta, path} = route
                if (meta.activeMenu) {
                    return meta.activeMenu
                }
                return path
            },
            routes() {
                return this.$router.options.routes.filter(item => {
                    return !item.hidden
                })
            },
            childroutes() {
                return function (list) {
                    return list.filter(item => {
                        return !item.hidden
                    })
                }
            }
        },
    }
</script>

<style lang="less" scoped>
    .iconfont {
        margin-right: 12px;
        margin-left: -2px;
        font-size: 18px;
        text-align: center
    }

    .sidebar-container {
        transition: width 0.28s;
        width: 190px !important;
        background-color: #304156;
        height: 100%;
        position: fixed;
        font-size: 0px;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 1703;
        overflow: hidden;
    }

    a {
        cursor: pointer;
        color: inherit;
        text-decoration: none;
    }

    .sidebar-logo {
        position: relative;
        width: 100%;
        height: 50px;
        line-height: 50px;
        background: #2b2f3a;
        text-align: center;
        overflow: hidden;

        a {
            display: inline-block;
            width: 100%;
            overflow: hidden;

            img {
                width: 32px;
                height: 32px;
                border-radius: 16px;
                vertical-align: middle;
                margin-right: 12px;
            }

            .sidebar-title {
                display: inline-block;
                margin: 0;
                color: #fff;
                font-weight: 600;
                line-height: 50px;
                font-size: 14px;
                font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
                vertical-align: middle;
            }
        }
    }

    .el-menu {
        border: none;
        height: 100%;
        width: 100% !important;
        background-color: rgb(48, 65, 86);
    }

    .menu-top {
        padding-left: 20px;
        color: rgb(191, 203, 217);
        background-color: rgb(48, 65, 86) !important;

    }

    .menu-top:hover {
        background-color: #263445 !important;
    }
</style>
<style lang="less">
    .el-menu-item {
        min-width: 190px !important;
        background-color: #1f2d3d !important;

        &:hover {
            background-color: #001528 !important;
        }
    }

    .el-scrollbar {
        height: 100%;
        overflow: hidden;
    }

    .sidebar-container {

        .el-scrollbar__wrap {
            height: 100%;
            overflow-x: hidden !important;
        }
    }
</style>