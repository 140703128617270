import Vue from 'vue'
import App from './App.vue'
import router from './router'


//element-ui
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import './assets/icon-font/iconfont.css'
// import './assets/icon-font/iconfont'

//mavonEditor
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

Vue.use(mavonEditor)
Vue.use(Element)

Vue.prototype.msgSuccess = function (msg) {
  this.$message.success(msg)
}

Vue.prototype.msgError = function (msg) {
  this.$message.error(msg)
}

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')