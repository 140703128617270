import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../components/Home";

Vue.use(VueRouter)

const routes = [
	{
		path: '/404',
		component: () => import('../views/404'),
		meta: {title: '404 NOT FOUND'},
		hidden: true
	},
	{
		path: '/login',
		component: () => import('../views/login'),
		meta: {title: '后台管理登录'},
		hidden: true
	},
	{
		path: '/',
		component: Home,
		redirect: '/manageHome',
		hidden: true,
		children: [
			{
				path: 'manageHome',
				name: 'ManageHome',
				component: () => import('../views/manageHome'),
				meta: {title: 'Manage', icon: 'dashboard'}
			}
		]
	},
	{
		path: '/blog',
		name: 'Blog',
		redirect: '/blog/write',
		component: Home,
		meta: {title: '博客管理', icon: 'el-icon-menu'},
		children: [
			{
				path: 'write',
				name: 'WriteBlog',
				component: () => import('../views/blog/blog/WriteBlog'),
				meta: {title: '写文章', icon: 'el-icon-edit'}
			},
			{
				path: 'edit/:id',
				name: 'EditBlog',
				component: () => import('../views/blog/blog/WriteBlog'),
				meta: {title: '编辑文章', icon: 'el-icon-edit'},
				hidden: true
			},
			{
				path: 'list',
				name: 'BlogList',
				component: () => import('../views/blog/blog/BlogList'),
				meta: {title: '文章管理', icon: 'el-icon-s-order'}
			},
			{
				path: 'category/list',
				name: 'CategoryList',
				component: () => import('../views/blog/category/CategoryList'),
				meta: {title: '分类管理', icon: 'el-icon-s-opportunity'}
			},
			{
				path: 'tag/list',
				name: 'TagList',
				component: () => import('../views/blog/tag/TagList'),
				meta: {title: '标签管理', icon: 'el-icon-price-tag'}
			},
		]
	},
	{
		path: '/page',
		name: 'Page',
		redirect: '/page/uploads',
		component: Home,
		meta: {title: '页面管理', icon: 'el-icon-document-copy'},
		children: [
			{
				path: 'uploads',
				name: 'Uploads',
				component: () => import('../views/page/television/Uploads'),
				meta: {title: '增加影视', icon: 'el-icon-edit-outline'}
			},
			{
				path: 'edit/:id',
				name: 'EditTV',
				component: () => import('../views/page/television/Uploads'),
				meta: {title: '编辑文章', icon: 'el-icon-edit-outline'},
				hidden: true
			},
			{
				path: 'tv/list',
				name: 'TVList',
				component: () => import('../views/page/television/TVList'),
				meta: {title: '影视管理', icon: 'el-icon-s-platform'}
			},
			{
				path: 'tv/category/list',
				name: 'CategoryList',
				component: () => import('../views/page/category/CategoryList'),
				meta: {title: '分类管理', icon: 'el-icon-s-opportunity'}
			},
			{
				path: 'Type/list',
				name: 'TypeList',
				component: () => import('../views/page/type/TypeList'),
				meta: {title: '类型管理', icon: 'el-icon-document'}
			},
			// {
			// 	path: 'about',
			// 	name: 'About',
			// 	component: () => import('../views/page/About'),
			// 	meta: {title: '关于我', icon: 'el-icon-tickets'}
			// },
		]
	},

	// 404 page must be placed at the end !!!
	{path: '*', redirect: '/404', hidden: true}
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

//挂载路由守卫
router.beforeEach((to, from, next) => {
	if (to.path !== '/login') {
		//获取token
		const tokenStr = window.localStorage.getItem('token')
		if (!tokenStr) return next("/login")
	}
	document.title = to.meta.title
	next()
})
export default router